function GetPanels() {
  const panelSquares = window.panels
  const newPanels = window.sortByProp(
    panelSquares.filter((x) => x.new),
    'title'
  )
  const others = window.uniqueByProp(panelSquares, newPanels, 'title')
  return [...newPanels, ...window.sortByProp(others, 'title')]
}

function GetCards() {
  return [
    {
      title: 'home.text_23',
      value: '435,8',
      valueSuffix: 'commons.billionsStar',
      valuePreffix: 'R$',
      extraInfo: 'home.text_28',
      link: 'https://www.gov.br/agricultura/pt-br/assuntos/politica-agricola/plano-safra/2023-2024/apresentacao-plano-safra-2023-24.pdf/',
      font: 'MAPA',
      showLink: ['development', 'homolog', 'production'],
    },
    {
      title: 'home.text_24',
      value: '1,150',
      valueSuffix: 'commons.trillions',
      valuePreffix: 'R$',
      link: 'https://www.gov.br/agricultura/pt-br/assuntos/politica-agricola/valor-bruto-da-producao-agropecuaria-vbp',
      font: 'MAPA',
      showLink: ['development', 'homolog', 'production'],
    },
    {
      title: 'home.text_25',
      value: '126,22',
      valueSuffix: 'commons.billions',
      valuePreffix: 'US$',
      link: window.statisticalLink + '/comercioExterior',
      font: 'Agrostat<br>Siscomex',
      showLink: ['development', 'homolog', 'production'],
    },
    {
      title: 'home.text_26',
      value: '41.766',
      valueSuffix: null,
      valuePreffix: null,
      link: 'http://pdet.mte.gov.br/novo-caged?view=default',
      font: 'MTP<br>Caged',
      showLink: ['development', 'homolog', 'production'],
    },
  ]
}

function GetNextPanel(currentPanel) {
  const panelSquares = window.panels
  const currentIndex = panelSquares.findIndex((x) => x.key === currentPanel)
  if (currentIndex + 1 <= panelSquares.length - 1) return panelSquares[currentIndex + 1]
  else return panelSquares[0]
}

function GetPreviousPanel(currentPanel) {
  const panelSquares = window.panels
  const currentIndex = panelSquares.findIndex((x) => x.key === currentPanel)
  if (currentIndex - 1 >= 0) return panelSquares[currentIndex - 1]
  else return panelSquares[panelSquares.length - 1]
}

function SetError(inputName, error) {
  const $ = window.$
  if (error) {
    $(`input[name='${inputName}'] + p`)[0].innerText = error
    $(`input[name='${inputName}']`).addClass('error')
  } else {
    $(`input[name='${inputName}'] + p`)[0].innerText = ''
    $(`input[name='${inputName}']`).removeClass('error')
  }
}

function GetFormData() {
  const $ = window.$
  const nome = $("input[name='Nome']")[0].value
  const email = $("input[name='Email']")[0].value
  const telefone = $("input[name='Telefone']")[0].value
  const areaAtuacao = $("input[name='Área']")[0].value
  return { nome, email, telefone, areaAtuacao }
}

function ValidateAll() {
  const { name, email, telefone, areaAtuacao } = GetFormData()

  if (name.length < 10) {
    SetError('Nome', window.AlpineI18n.t('home.text_19'))
  } else SetError('Nome', null)
  if (email.length < 10) SetError('Email', window.AlpineI18n.t('home.text_20'))
  else SetError('Email', null)
  if (telefone.length < 10) SetError('Telefone', window.AlpineI18n.t('home.text_21'))
  else SetError('Telefone', null)
  if (areaAtuacao.length < 10) SetError('Área', window.AlpineI18n.t('home.text_22'))
  else SetError('Área', null)

  return name.length >= 10 && email.length >= 10 && telefone.length >= 10 && areaAtuacao.length >= 10
}

function ToggleAll(state) {
  const name = $("input[name='Nome']")
  const email = $("input[name='Email']")
  const phone = $("input[name='Telefone']")
  const area = $("input[name='Área']")
  const button = $('#contact_form_send')

  name.prop('disabled', !state)
  email.prop('disabled', !state)
  phone.prop('disabled', !state)
  area.prop('disabled', !state)
  button.prop('disabled', !state)
}

function SendForm() {
  if (!ValidateAll()) return
  ToggleAll(false)
  fetch(window.contactFormLink, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(GetFormData()),
  }).finally((res) => {
    ToggleAll(true)
    $('#contact_form_feedback').addClass('active')
    $('#contact_form')[0].reset()
    setTimeout(() => {
      $('#contact_form_feedback').removeClass('active')
    }, 5000)
  })
}
